import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { IGetServerSidePropsData } from './index.types'
import { client } from '@/libs/apollo'

import Head from '@/components/head/head.component'
import HeroSection from '@/components/hero-section/hero-section.component'
import PageLayout from '@/layouts/page/page.layout'
import CardDirectory from '@/components/card-directory/card-directory.component'

import {
  CATEGORIES_WITH_POSTS,
  ICategoriesQueryData,
} from '@/graphql/category/category.queries'
import {
  POSTS_QUERY,
  IPostsQuery,
  IPostsQueryVars,
} from '../graphql/post/post.queries'

export const getServerSideProps: GetServerSideProps<IGetServerSidePropsData> =
  async (context) => {
    try {
      const { data: categoriesData } = await client.query<ICategoriesQueryData>(
        {
          query: CATEGORIES_WITH_POSTS,
        }
      )

      const categories = categoriesData.categories.items.filter(
        (item) => item.slug !== 'uvod'
      )

      const { data: postsData } = await client.query<
        IPostsQuery,
        IPostsQueryVars
      >({
        query: POSTS_QUERY,
        variables: {
          query: {
            limit: 10,
            offset: 0,
          },
        },
      })

      const { data: patreonPostsData } = await client.query<
        IPostsQuery,
        IPostsQueryVars
      >({
        query: POSTS_QUERY,
        variables: {
          query: {
            limit: 10,
            offset: 0,
            isPublic: false,
          },
        },
      })

      return {
        props: {
          patreonPosts: patreonPostsData.posts.items,
          latestPosts: postsData.posts.items,
          categories: categories,
        }, // will be passed to the page component as props
      }
    } catch (error) {
      console.log(error)
      return {
        props: {}, // will be passed to the page component as props
      }
    }
  }

export default function Home({
  latestPosts,
  patreonPosts,
  categories,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <PageLayout categories={categories ?? []}>
      <Head
        title="Zamysli sa a nechaj sa šokovať | IVETARS"
        description="Čítaj každý týždeň informačný článok na témy: DREDY, PIERCING, VEGÁNSTVO, TELESNÉ MODIFIKÁCIE. Staň sa mojím Patrónom a odomkni si prístup k zaujímavým benefitom."
        image="https://res.cloudinary.com/coderkin/image/upload/v1680204771/ivetars-blog/meta-placeholder_zi2zjr.png"
      ></Head>
      <HeroSection posts={latestPosts ?? []} />
      <CardDirectory
        title={'PATRÓNI ČÍTAJÚ'}
        categoryLink={'https://www.patreon.com/ivetarsblog'}
        posts={patreonPosts ?? []}
      />
      {categories &&
        categories.map((category) => (
          <CardDirectory
            key={category.id}
            title={category.name}
            categoryLink={`/kategorie/${category.slug}`}
            posts={category.posts}
          />
        ))}
    </PageLayout>
  )
}
